import {
  ComponentRef,
  createNgModule,
  Inject,
  Injectable,
  Injector,
  NgModuleRef, ProviderToken,
  QueryList,
  Type,
  ViewContainerRef,
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
// NOTE: Do not import anything from ngx-intl-tel-input because it will hardly increase bundle size

export type ComponentBlock = {
  ref: ViewContainerRef;
  injector?: Injector;
  loaded: boolean;
  modulePath?: string,
  componentPath?: string,
  onLoad?: (block: ComponentBlock)=>any;
  onCreate?: (block: ComponentBlock)=>any; // Called after component has been created
  host: any;
  name: string;
  componentRef?: any; // Reference to newly created component
}

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class LazyService {

  constructor(private injector: Injector,
              @Inject(DOCUMENT) public document: Document) {
  }

  /**
   * Used to lazy load service
   * @param providerLoader
   */
  async get<T>(providerLoader: () => Promise<ProviderToken<T>>) {
    return this.injector.get(await providerLoader());
  }

  /**
   * Lazy load module and create component
   * @param importedFile
   * @param block
   */
  public loadComponent<T>(
    importedFile: T,
    block: ComponentBlock
  ): ComponentRef<any> {
    if (environment.debug || !environment.production) {
      console.log('Load component', block.name, <any>importedFile);
    }
    const module = (<any>importedFile)[Object.keys(<any>importedFile)[0]];
    const components = module.components;
    const componentToOpen: Type<any> = components[block.name as keyof typeof components];
    const moduleRef: NgModuleRef<any> = createNgModule(module, block?.injector ?? this.injector);
    block.ref.clear();
    block.componentRef = block.ref.createComponent(componentToOpen, { ngModuleRef: moduleRef });
    block.loaded = true;
    if (typeof block?.onCreate === 'function') {
      block.onCreate(block);
    }
    if (block?.host?.cd) {
      block.host.cd.detectChanges();
    }
    return block.componentRef;
  }

  /**
   * Lazy load stylesheet
   * @param styleName
   * @param elementId
   */
  public loadStylesheet(styleName: string, elementId: string) {
    const head = this.document.getElementsByTagName('head')[0];
    let chatCss = this.document.getElementById(elementId) as HTMLLinkElement;
    if (!chatCss) {
      chatCss = this.document.createElement('link');
      chatCss.id = elementId;
      chatCss.rel = 'stylesheet';
      chatCss.href = `${styleName}`;
    }
    chatCss.href = `${styleName}`;
    head.appendChild(chatCss);
  }

  /**
   * Creates PhoneComponent (wrapper for intl-tel-input component);
   * @param containerRef
   * @param inputs
   * @param host
   */
  public createPhoneInput(containerRef: QueryList<ViewContainerRef>, inputs: any[], host: any) {
    if (containerRef?.first) {
      this._createPhoneInput(containerRef, inputs, host);
    } else {
      containerRef.changes.subscribe(() => {
        // The container has been added to the DOM
        if (containerRef.first) {
          this._createPhoneInput(containerRef, inputs, host);
        }
      });
    }
  }

  private _createPhoneInput(containerRef: QueryList<ViewContainerRef>, inputs: any[], host: any) {
    import('../../core/components/lazy/lazy-phone.module').then((importedFile) => {
      this.loadComponent(importedFile, {
        ref: containerRef.first,
        loaded: false,
        onCreate: (phone: any) => {
          inputs.forEach(input => {
            const [key, value] = Object.entries(input)[0];
            phone.componentRef.setInput(key, value);
          });
        },
        host: host,
        name: 'phone'
      });
    });
  }

  public createCaptcha(containerRef: QueryList<ViewContainerRef>, form: FormGroup, onSubmit: ()=>void, host: any, callback: (component: any)=>void) {
    if (containerRef?.first) {
      this._createCaptcha(containerRef, form, onSubmit, host, callback);
    } else {
      containerRef.changes.subscribe(() => {
        // The container has been added to the DOM
        if (containerRef?.first) {
          this._createCaptcha(containerRef, form, onSubmit, host, callback);
        }
      });
    }
  }

  private _createCaptcha(containerRef: QueryList<ViewContainerRef>, form: FormGroup, onSubmit: ()=>void, host: any, callback: (component: any)=>void) {
    import('../../core/components/lazy/lazy-captcha.module').then((importedFile) => {
      this.loadComponent(importedFile, {
        ref: containerRef.first,
        loaded: false,
        onCreate: (captcha: any) => {
          captcha.componentRef.setInput('form', form);
          captcha.componentRef.setInput('submit', onSubmit);
          if (typeof callback === 'function') {
            callback(captcha.componentRef.instance);
          }
        },
        host: host,
        name: 'captcha'
      });
    });
  }
}

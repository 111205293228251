import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

import {HttpService} from './http.service';
import {AuthService} from './auth.service';
import {apiUrls} from '../config';
import {BufferService} from './buffer.service';
import {ToolsService} from './tools.service';
import {ToastService} from './toast.service';
import {MiddleTruncatePipe} from '../pipes/mid-truncate.pipe';
import {ChatService} from './chat.service';

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  public bell: {
    show: boolean,
    list: any[]
  } = {
    show: false,
    list: []
  };

  constructor(private http: HttpService,
              private auth: AuthService,
              private toast: ToastService,
              private buffer: BufferService,
              private tools: ToolsService,
              private chat: ChatService,
              private midTruncate: MiddleTruncatePipe,
              private router: Router) {
    this.buffer.messageReceived$.pipe(
      switchMap((message: any) => {
        const forMe = this.forMe(message);
        if (forMe) {
          if (this.chat.activeGuid !== message?.chat?.guid) {
            let clickHandler = null;
            if (message?.booking?.id) {
              // Open booking
              clickHandler = () => {
                this.router.navigate(['/account', 'booking', 'edit', message.booking.id]);
              }
            } else if (message?.chat?.guid && message?.chat?.booking.chat?.guid) {
              // Open chat
              clickHandler = () => {
                this.chat.openChat$.next(message?.chat?.booking);
              }
            }
            this.toast.show(this.midTruncate.transform(message.value, [100]), {
              title: this.midTruncate.transform(message.chat?.topic, [30]),
              icon: 'bell-on',
              status: 'info',
              autohide: !clickHandler,
              click: clickHandler
            });
          }
        }
        return forMe || message?.refresh ? this.list$() : of({});
      })
    ).subscribe((response: any) => {
      this._processResponse(response);
    });

    this.auth.userInfoChange$.subscribe((userInfo: any) => {
      if (userInfo === null) {
        // Clear notifications, hide bell
        this._processResponse(null);
      }
    });
  }

  list$() {
    if (this.auth.identity) {
      return this.http.list(apiUrls.notification.list);
    }
    return of({});
  }

  seen(data: any) {
    this.http.post(apiUrls.notification.seen, data, () => null).subscribe((response: any) => {
      this._processResponse(response);
    });
  }

  private _processResponse(response: any) {
    if (response?.success && Array.isArray(response?.data) && response.data.length) {
      this.bell.show = true;
      this.bell.list = response.data;
      console.log('notifications', response.data);
    } else {
      this.bell.show = false;
      this.bell.list = [];
    }
  }

  private forMe(message: any) {
    return !this.tools.isEmpty(message) && this.auth.identity && message?.to?.id === this.auth.identity.id;
  }
}

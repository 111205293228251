<ul class="navbar-nav navbar-nav-scroll align-items-lg-center" style="max-height: 35rem;">
  <ng-container *ngFor="let item of menuItems">
    <li class="nav-item dropdown" *ngIf="displayMenuItem(item)">
      <a *ngIf="!item.subItems; else withSubitems" class="nav-link"
         (click)="global.onMenuClick($event)"
         href="{{item.link}}"
         id="topnav-components-{{item?.id}}"
         routerLink="{{item.link}}"
         role="button">{{item.label}}</a>

      <ng-template #withSubitems>
        <a class="nav-link dropdown-toggle"
           (click)="global.onMenuClick($event)"
           href="{{item.link}}"
           id="topnav-sub-components-{{item?.id}}"
           role="button">{{item.label}}</a>
      </ng-template>

      <ul class="dropdown-menu" aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
        <ng-container *ngFor="let subitem of item.subItems; let last = last" ngProjectAs="li">
          <a class="col dropdown-item side-nav-link-ref cursor-pointer" *ngIf="!hasItems(subitem)"
             (click)="menuClick($event, subitem)"
             [routerLink]="subitem.link !== '#' ? [subitem.link] : null">{{subitem.label}}</a>

          <div class="dropdown" *ngIf="hasItems(subitem)">
            <a class="dropdown-item dropdown-toggle" href="javascript:void(0);"
               (click)="menuClick($event, subitem)">{{ subitem.label }}
              <div class="arrow-down"></div>
            </a>
            <ul class="dropdown-menu">
              <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                <a class="dropdown-item" *ngIf="!hasItems(subSubitem)"
                   routerLink="{{subSubitem.link}}"
                   routerLinkActive="active">{{ subSubitem.label }}</a>
              </ng-template>
            </ul>
          </div>
          <li class="dropdown-divider" *ngIf="!last"></li>
        </ng-container>
      </ul>

    </li>
  </ng-container>
  <!-- Notification bell -->
  <li class="nav-item dropdown" *ngIf="global.notification.bell?.show">
    <a class="nav-link dropdown-toggle" role="button"
       (click)="global.onMenuClick($event)"
       href="javascript:void(0);"><i class="fi-bell-on text-primary swing me-1"></i></a>
    <ul class="dropdown-menu bell" aria-labelledby="topnav-dashboard">
      <ng-container *ngFor="let notification of global.notification.bell.list; let last = last">
        <li class="col dropdown-item side-nav-link-ref cursor-pointer"
            (click)="menuClick($event, notification)">
          <p class="fw-bold fs-sm mb-1">{{notification.title}}</p>
          <span class="fw-normal fs-xs"><span class="fw-bold fs-xxs me-1">{{notification.created_at}}</span>{{notification.message}}</span>
        </li>
        <li class="dropdown-divider" *ngIf="!last"></li>
      </ng-container>
    </ul>
  </li>
</ul>

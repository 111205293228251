import {Injectable} from '@angular/core';

import {AbstractService} from './abstract.service';
import {apiUrls} from '../config';

@Injectable()
export class CountryService extends AbstractService {

  override useCache = true;
  override endpoint = apiUrls.country;
  override name = 'country';

}

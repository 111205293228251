import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {take} from 'rxjs/operators';

import {HttpService} from './http.service';
import {apiUrls} from '../config';
import {ToolsService} from './tools.service';
import {AbstractService} from './abstract.service';
import {AppData} from '../appdata';
import {Params} from '@angular/router';


@Injectable()
export class OptionService extends AbstractService {

  override useCache = true;
  override endpoint = apiUrls.options;
  private loaded = false;
  public listingLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(override http: HttpService, private tools: ToolsService, private appData: AppData) {
    super(http);
    if (this.appData?.options && !this.loaded) {
      this.list = this.appData.options;
      this.loaded = true;
      this.listingLoaded$.next(true);
    } else {
      // Load options
      this.list$().pipe(take(1)).subscribe(response => {
        if (response?.success && response?.data) {
          this.list = response.data;
          if (!this.loaded) {
            // This part should be called just once
            this.loaded = true;
            this.listingLoaded$.next(true);
          }
          this.listingUpdated$.next(true);
        }
      });
    }
  }

  get(name: string, def: any = ''): any {
    const option = this.getObj(name);
    if (option) {
      switch (option.altValue) {
        case 'boolean':
          return this.tools.toBool(option.value);
        case 'integer':
          return parseInt(option.value);
        case 'float':
          return parseFloat(option.value);
        case 'string':
          return <string>option.value;
      }
    }
    if (name.endsWith('.enabled')) {
      return this.tools.toBool(def);
    }
    return def;
  }

  getObj(name: string): any {
    if (Array.isArray(this.list)) {
      return this.list.find(item => {
        return item.name === name;
      });
    }
    return null;
  }

  getFileInfo$(params: Params) {
    if (params?.['option']?.['skipInfo'] && params?.['option']?.['link']) {
      return of({success: true, data: {link: params['option']['link']}});
    }
    return this.http.post(this.endpoint.file.info, params, () => null);
  }
}

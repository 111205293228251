import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {CountdownFormatFn} from 'ngx-countdown';
import {BehaviorSubject, Observable, of} from 'rxjs';

import {HttpService, Response} from './http.service';
import {apiUrls} from '../config';
import {ToastService} from './toast.service';
import {AbstractService} from './abstract.service';
import {ToolsService} from './tools.service';
import {AuthService} from './auth.service';

@Injectable()
export class BookingService extends AbstractService {

  override endpoint = apiUrls.booking;
  override name = 'booking';
  timeouts = [
    {value: 0, name: ''},
    {value: 30, name: '30 minutes'},
    {value: 60, name: '1 hour'},
    {value: 120, name: '2 hours'},
    {value: 480, name: '8 hours'},
    {value: 1440, name: '24 hours'},
    {value: 2880, name: '48 hours'},
    {value: 10080, name: '1 week'},
    {value: 525600, name: 'no limit'},
  ];

  public checkout$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  public statuses = [];
  private unknownStatus = {
    icon: 'fi-help',
    items: [],
    color: 'danger',
    title: 'unknown',
    display: {
      listing: false,
      calendar: false
    },
    total: 0,
  };

  constructor(override http: HttpService,
              private toast: ToastService,
              private tools: ToolsService,
              private auth: AuthService) {
    super(http);
    this.auth.userInfoChange$.subscribe({
      next: (userInfo: any) => {
        if (userInfo !== null && this.statuses.length === 0) {
          this.statuses$().subscribe(response => {
            if (response?.success === true && response?.data) {
              this.statuses = response.data;
            }
          });
        }
      }
    });
  }

  override deleteImage$(id: number, params: Params = {}) {
    return this.badResponse();
  }

  override imageUrl(model: any, image: any = null, thumb: string | null = null) {
    return '';
  }

  daily$(params: Params = {}): Observable<Response> {
    if (this.useCache && this.list && Array.isArray(this.list) && this.list.length) {
      return of({success: true, data: this.list, cached: true});
    }
    if (this.endpoint?.daily) {
      return this.http.list(this.endpoint.daily, params);
    }
    return this.badResponse();
  }

  /**
   * must be passed either boatId or userId
   * @param params
   */
  schedule$(params: Params) {
    if (this.endpoint?.schedule) {
      return this.http.post(this.endpoint.schedule, params, () => null);
    }
    return this.badResponse();
  }

  statuses$(): Observable<Response> {
    if (this.endpoint?.statuses) {
      return this.http.list(this.endpoint?.statuses);
    }
    return this.badResponse();
  }

  status(status: string, booking: any, params: any = {}) {
    if (status === 'checkout') {
      this.checkout(booking);
      return;
    }
    let confirmation = `Do you want to ${status} this booking enquiry?`;
    if (status === 'approve' && params?.method === 'cash') {
      confirmation = `Confirm cash payment?`;
    }

    if (confirm(confirmation)) {
      let value = 'archived';
      switch (status) {
        case 'accept':
          value = 'pending';
          break;
        case 'complete':
          value = 'completed';
          break;
        case 'reserve':
          value = 'reserved';
          break;
        case 'approve':
          value = 'approved';
          break;
        case 'refund':
          value = 'refunded';
          break;
      }
      params = {
        id: booking.id,
        param: `booking.status`,
        value: value,
        type: 'string',
        clientTime: this.tools.timestampWithoutTimezone(new Date()),
        ...params
      };
      this.update$(params).subscribe((response: any) => {
        if (response?.success === true && response?.message) {
          this.toast.show(response.message, {
            title: response?.title ?? 'Saved',
            status: 'success',
          });
          this.listingUpdated$.next(true);
          this.itemUpdated$.next(params);
        }
      });
    }
  }

  /**
   * @param booking
   */
  delete(booking: any) {
    if (confirm(`Do you want to delete booking?`)) {
      /**
       * According to ticket 3107 - if they delete it should goto archive
       */
      this.status('archive', booking);
      // this.delete$(booking.id).subscribe((response: any) => {
      //   if (response?.success === true && response?.message) {
      //     this.toast.show(response.message, {
      //       title: response?.title ?? 'Archived',
      //       icon: 'trash',
      //       status: 'success',
      //     });
      //     this.listingUpdated$.next(true);
      //   }
      // });
    }
  }

  checkout(booking: any) {
    this.checkout$.next(booking);
  }

  /**
   * Return status details by name
   * @param status
   */
  public byStatus(status: string): any {
    const obj = this.statuses.find(item => {
      return status === Object.keys(item)[0]
    });
    return obj ? Object.values(obj)[0] : this.unknownStatus;
  }

  public statusIcon(status: string): string {
    return this.byStatus(status).icon;
  }

  public statusTitle(status: string): string {
    return this.byStatus(status).title;
  }

  /**
   * Calculates difference between now and booking expires time and sets leftTime property of the countdown timer (starts the timer),
   * also adds hours to timer format in case timeLeft is more than 60 minutes
   * @param booking
   */
  public countdown(booking: any) {
    const expireDate = new Date(booking.expires);
    const startTime = this.tools.timestampWithoutTimezone(new Date());
    const diff = this.tools.timestampWithoutTimezone(expireDate) - startTime;
    const result = {
      format: 'mm:ss',
      leftTime: 0,
      formatDate: this.formatDate
    }
    if (diff > 0) {
      const seconds = diff / 1000;
      // Check possible formats in this.countdownTimeUnits
      result.format = seconds > 86400 ? 'Dd H:m:s' : (seconds > 3600 ? 'H:m:s' : 'm:s');
      result.leftTime = seconds;
    }
    return result;
  }

  public countdownTimeUnits: Array<[string, number]> = [
    ['Y', 1000 * 60 * 60 * 24 * 365], // years
    ['M', 1000 * 60 * 60 * 24 * 30], // months
    ['D', 1000 * 60 * 60 * 24], // days
    ['H', 1000 * 60 * 60], // hours
    ['m', 1000 * 60], // minutes
    ['s', 1000], // seconds
    ['S', 1] // milliseconds
  ];

  public formatDate?: CountdownFormatFn = ({ date, formatStr, timezone }) => {
    let duration = Number(date || 0);

    return this.countdownTimeUnits.reduce((current, [name, unit]) => {
      if (current.indexOf(name) !== -1) {
        const v = Math.floor(duration / unit);
        duration -= v * unit;
        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
          let length = match.length;
          if (['H', 'm', 's'].includes(name) && v < 10 && length === 1) {
            length += 1;
          }
          return v.toString().padStart(length, '0');
        });
      }
      return current;
    }, formatStr);
  };
}

import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

import {apiUrls} from '../config';
import {AbstractService} from './abstract.service';
import {HttpService} from './http.service';

@Injectable()
export class PlaceService extends AbstractService {

  override useCache = true;
  override endpoint = apiUrls.place;
  override name = 'place';

  constructor(override http: HttpService,
              public domSanitizer: DomSanitizer) {
    super(http);
  }

}

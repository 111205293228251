import {HttpHandlerFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';

import {AuthService} from './auth.service';
import {environment} from '../../../environments/environment';

export function authInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn){
  const auth = inject(AuthService);
  const identity = auth.identity;
  if (environment.debug) {
    console.log('INTERCEPT', request, identity);
  }
  const _bypassUrls = [
    'https://maps.googleapis.com/',
  ];
  const bypassUrl = _bypassUrls.some((bypassUrl) => request.url.startsWith(bypassUrl));
  if (identity && !bypassUrl) {
    const cloned = request.clone({
      withCredentials: true,
      headers: request.headers
      .set('Authorization', `Bearer ${identity.token}`)
      .set('X-XSRF-TOKEN', identity.csrfToken ?? ''),
      params: request.params
      .set('currentRole', identity?.currentRole ?? '')
    });
    return next(cloned);
  }
  return next(request);
}

import {Component} from '@angular/core';
import {RouterLink} from '@angular/router';
import {NgIf, NgStyle, NgClass, NgFor} from '@angular/common';

import {AbstractComponent} from '../../core/components/abstract.component';
import {ACCOUNT_MENU} from './menu';
import {MiddleTruncatePipe} from '../../core/pipes/mid-truncate.pipe';

/**
 * Nav (Top) Menu Component
 */
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgStyle,
    NgClass,
    NgFor,
    MiddleTruncatePipe,
  ],
})
export class NavMenuComponent extends AbstractComponent {

  public show = false;
  private _menu = ACCOUNT_MENU;

  override ngOnInit(): void {
    super.ngOnInit();
  }

  get menu(): any[] {
    return this._menu;
  }

  userInfo() {
    this.global.navigate(['/account', 'info']);
  }

}

import {Directive, ElementRef, Input, OnInit} from '@angular/core';

import {SocialAuthService} from '../services/social/social-auth.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'google-signin-button',
  standalone: true
})
export class GoogleSigninButtonDirective implements OnInit {

  @Input() selectable = true;
  private _rendered = false;

  constructor(private el: ElementRef, protected socialAuth: SocialAuthService) {
    this.socialAuth.initialize();
  }

  ngOnInit() {
    if (!this.selectable) return;
    this.socialAuth.google.subscribe((google: any) => {
      if (google && !this._rendered) {
        google.accounts.id.renderButton(this.el.nativeElement, {
          scope: 'profile email',
          type: 'standard',
          size: 'large',
          text: 'continue_with',
          theme: 'filled_blue' // outline
        });
        this._rendered = true;
      }
    });
  }
}

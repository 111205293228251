export type UserLocation = {
  iso_code: string;
  country: string;
  city: string;
  timezone: string;
  currency: string;
}

export type Currency = {
  code: string;
  countryCode: string;
  flag?: string;
  disabled?: boolean;
  rate?: number;
}

export class AppData {
  // base64 encoded pages list
  public bbPages: any = null;
  public boats: any = null;
  public options: any = null;
  public marinas: any = null;
  public location: UserLocation|null = null;
  public exchangeRates: any = null;
}

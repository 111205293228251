<div class="modal-body px-0 py-2 py-sm-0">
  <button class="btn-close position-absolute top-0 end-0 mt-3 me-3" type="button" data-bs-dismiss="modal" (click)="activeModal.dismiss('Cross click')"></button>
  <div class="row mx-0 align-items-center">
    <div class="col-lg-5 border-end-md p-4 p-sm-5">
      <h2 class="h3 mb-lg-4">{{title|titlecase}} registration</h2>
      <img class="mx-auto d-none d-lg-block mb-4" src="{{assets}}/img/logo/logo.svg" width="244" alt="Illustration" loading="lazy">
      <ul class="list-unstyled mb-3 mb-sm-4">
        <li class="d-flex mb-2" *ngFor="let feature of features"><i class="fi-check-circle text-primary mt-1 me-2"></i><span>{{feature}}</span></li>
      </ul>
      <div class="mt-sm-4 pt-md-3 fs-sm d-none d-lg-block">
        <ng-template [ngTemplateOutlet]="signInLink"></ng-template>
      </div>
    </div>
    <div class="col-lg-7 px-4 pt-2 pb-4 px-sm-4 pb-sm-4 pt-md-5">
      <app-signup-prompt *ngIf="role" [role]="role"></app-signup-prompt>
      <div class="mt-4 mt-sm-5 d-lg-none">
        <ng-template [ngTemplateOutlet]="signInLink"></ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template #signInLink>
  <span class="fs-sm-xs">Already have an account?<a href="#" (click)="signin()" class="ms-1">Sign in</a></span>
</ng-template>

import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';

import {HttpService, Response} from './http.service';
import {apiUrls, defaultLayout, PageLayout} from '../config';
import {AbstractService} from './abstract.service';

@Injectable()
export class ContentService extends AbstractService {

  override endpoint = apiUrls.content;
  override name = 'content';

  constructor(override http: HttpService,
              public domSanitizer: DomSanitizer) {
    super(http);
  }

  public$(params: Params): Observable<Response> {
    let url: string = apiUrls.content.public;
    if (url && params?.['name']) {
      url += '/' + params['name'];
    }
    return url ? this.http.list(url, params) : of({success: false, data: []});
  }

  all$(params: Params = {}): Observable<Response> {
    if (params?.['type']) {
      return this.http.list(apiUrls.content.all + '/' + params['type'], params);
    }
    return of({success: false, data: []});
  }

  add$(params: Params = {}) {
    return this.http.add(apiUrls.content.add, params);
  }

  seoCheck$() {
    return this.http.post(apiUrls.content.seo.check, {}, () => null);
  }

  categories() {
    return this.http.list(apiUrls.content.category.list);
  }

  routes() {
    return this.http.list(apiUrls.content.route.list);
  }

  tags() {
    return this.http.list(apiUrls.content.tag.list);
  }

  public initLayout(content: any) {
    const result: PageLayout = {} as PageLayout;
    if (!content?.pageLayout) {
      if (!content?.layout) {
        content['layout'] = defaultLayout;
      }
      if (Array.isArray(content?.layout)) {
        content.layout.forEach((entry: any) => {
          result[entry.id as keyof PageLayout] = content.layout.find((item: any) => {
            return item.id === entry.id;
          });
        });
        content.pageLayout = result;
      }
    }
  }
}

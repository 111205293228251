import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { AppData } from './app/core/appdata';
import { authInterceptor } from './app/core/services/auth.interceptor';
import { ToolsService } from './app/core/services/tools.service';
import { PaymentSystemService } from './app/core/services/payment-system.service';
import { CountryService } from './app/core/services/country.service';
import { ChatService } from './app/core/services/chat.service';
import { BufferService } from './app/core/services/buffer.service';
import { GlobalService } from './app/core/services/global.service';
import { OptionService } from './app/core/services/option.service';
import { ApiService } from './app/core/services/api.service';
import { PlaceService } from './app/core/services/place.service';
import { MarinaService } from './app/core/services/marina.service';
import { ContentService } from './app/core/services/content.service';
import { BookingService } from './app/core/services/booking.service';
import { BoatService } from './app/core/services/boat.service';
import { GtagService } from './app/core/services/gtag.service';
import { WindowRefService } from './app/core/services/window-ref.service';
import { HttpService } from './app/core/services/http.service';
import { MiddleTruncatePipe } from './app/core/pipes/mid-truncate.pipe';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, AppRoutingModule),
    MiddleTruncatePipe,
    CookieService,
    HttpService,
    WindowRefService,
    GtagService,
    BoatService,
    BookingService,
    ContentService,
    MarinaService,
    PlaceService,
    ApiService,
    OptionService,
    GlobalService,
    BufferService,
    ChatService,
    CountryService,
    PaymentSystemService,
    ToolsService,
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    AppData,
    {
      provide: AppData,
      useValue: window?.['APP_DATA' as unknown as number]
    },
    provideHttpClient(withInterceptors([authInterceptor])),
  ]
})
  .catch(err => console.error(err));

import {Injectable} from '@angular/core';

import {WindowRefService} from './window-ref.service';

export type GtagEvent = {
  event: any;
  pageName?: any;
  category?: any;
  action?: any;
  label?: any;
  value?: any;
};

@Injectable({
  providedIn: 'root'
})
export class GtagService {

  private window;

  constructor (private _windowRef: WindowRefService) {
    this.window = this._windowRef.nativeWindow; // initialise the window to what we get from our window service
  }

  // TODO: rename somehow
  private pingHome(obj: GtagEvent) {
    if (obj && this.window?.dataLayer) {
      this.window.dataLayer.push(obj);
    } else {
      console.warn('GtagService: pingHome: obj or window.dataLayer is undefined');
    }
  }

  logBookingEnquiry(url: any) {
    const hit = {
      event: 'booking-enquiry',
      pageName: url
    };
    this.pingHome(hit);
  }

  logContactUs(url: any) {
    const hit = {
      event: 'contact-us-request',
      pageName: url
    };
    this.pingHome(hit);
  }

  logEvent(event: any, category: any, action: any, label: any) {
    const hit = {
      event:event,
      category:category,
      action:action,
      label: label
    }
    this.pingHome(hit);
  }

}

import {Component, Input} from '@angular/core';
import {NgIf, NgClass} from '@angular/common';
import {UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';

import {constant} from '../../core/config';
import {environment} from '../../../environments/environment';
import {AbstractAuthComponent} from './abstract-auth.component';
import {GoogleSigninButtonDirective} from '../../core/directives/google-signin-button.directive';

@Component({
  selector: 'app-login-prompt',
  templateUrl: './login-prompt.component.html',
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    GoogleSigninButtonDirective,
  ],
})
export class LoginPromptComponent extends AbstractAuthComponent {

  private _email = '';
  private _phone = '';
  private _mode = constant.SIGNIN_UNDEFINED;

  @Input() canSwitch = true;
  @Input() isNewUser = false;
  @Input() userToken: string | null = null;
  @Input()
  get signInMode(): number {
    return this._mode;
  }
  set signInMode(val: number) {
    this._mode = val;
    if (this.loginForm) {
      this.controls['mode']?.patchValue(this._mode);
      this.loginForm.updateValueAndValidity();
    }
  }

  @Input()
  get email(): string {
    return this._email;
  }
  set email(val: string) {
    this._email = val;
    if (this.loginForm) {
      this.controls['emailOrPhone']?.patchValue(this._email);
      this.loginForm.updateValueAndValidity();
    }
  }

  @Input()
  get phone(): string {
    return this._phone;
  }
  set phone(val: string) {
    this._phone = val;
    if (this.loginForm) {
      this.controls['emailOrPhone']?.patchValue(this._phone);
      this.loginForm.updateValueAndValidity();
    }
  }

  withEmail: boolean | undefined = true;
  isPhone = false;
  fieldTextType!: boolean;
  loginForm!: UntypedFormGroup;
  countries: any[] = []; // countries;
  assets = constant.IMAGE_PATH;
  constant = constant;
  isSending = false;

  override ngOnInit(): void {
    super.ngOnInit();

    this.withEmail = undefined;
    /**
     * Bootstrap validation form data
     */
    this.loginForm = this.formBuilder.group({
      emailOrPhone: [this.email, [Validators.required]],
      password: ['', [this.global.validator.conditional('otpCode')]],
      otpCode: ['', [this.global.validator.conditional('password')]],
      isPhone: false,
      mode: this.signInMode
    });

    this.controls['emailOrPhone'].valueChanges.subscribe((value: string) => {
      this.isPhone = value.match(constant.PHONE_PATTERN) !== null;
      this.controls['isPhone'].patchValue(this.isPhone);
    });
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  reset() {
    this.loginForm.reset();
    this.loginForm.updateValueAndValidity();
    this.signInMode = constant.SIGNIN_UNDEFINED;
  }

  withPassword() {
    this.signInMode = constant.SIGNIN_WITH_PASSWORD;
  }

  withOtp() {
    this.signInMode = constant.SIGNIN_WITH_OTP;
    this.sendLink();
  }

  withFacebook() {
    // this.global.auth.loginWithFacebook((user: SocialUser) => {
    //   if (environment.debug) {
    //     console.log('fb auth', user);
    //   }
    // });
  }

  sendLink() {
    this.isSending = true;
    this.global.auth.sendLoginLink(this.loginForm.value).subscribe(response => {
      const message = response?.message ?? 'Unknown status';
      this.global.toast.show(message, {
        title: response?.success === true ? 'Sent' : 'Error',
        icon: 'security',
        status: response?.success === true ? 'info' : 'danger'
      });
      if (response?.success === true) {
        this.loginForm.updateValueAndValidity();
      }
      this.isSending = false;
    });
  }

  /**
   * Bootstrap validation form submit method
   */
  validSubmit(modal: NgbActiveModal) {
    if (this.controls['emailOrPhone']?.invalid) {
      this.global.toast.show(`Not an email or phone number`, {
        title: 'Error',
        status: 'danger'
      });
    } else {
      if (this.signInMode !== constant.SIGNIN_UNDEFINED) {
        if (!environment.production) {
          // this.global.toast.show(`Good ${this.signInMode === constant.SIGNIN_WITH_EMAIL ? 'email' : 'phone'}`);
        }
      }
      if (this.signInMode === constant.SIGNIN_WITH_PASSWORD && this.controls['password'].valid) {
        if (this.isNewUser) {
          // Create (set) password and login
          this.auth(this.global.auth.password({
            email: this.controls['emailOrPhone'].value,
            password: this.controls['password'].value,
            token: this.userToken
          }));
        } else {
          this.login();
        }
      } else if (this.signInMode === constant.SIGNIN_WITH_OTP && this.controls['otpCode'].valid) {
        this.login();
      }
    }
    this.submit = true;
  }

  private login() {
    let loginFn: Observable<object> = new Observable<object>();
    if (this.signInMode === constant.SIGNIN_WITH_OTP) {
      const data = {
        otpCode: this.controls['otpCode']?.value,
        ...(this.isPhone && {phone: this.controls['emailOrPhone']?.value}),
        ...(!this.isPhone && {email: this.controls['emailOrPhone']?.value}),
      };
      loginFn = this.global.auth.otpLogin(data);
    } else if (this.signInMode === constant.SIGNIN_WITH_PASSWORD) {
      loginFn = this.global.auth.login(this.loginForm.value);
    }
    this.auth(loginFn);
  }

  /**
   * Returns form
   */
  get controls() {
    return this.loginForm.controls;
  }

  get modePassword(): boolean {
    return this.signInMode === constant.SIGNIN_WITH_PASSWORD;
  }

  get modeOtp(): boolean {
    return this.signInMode === constant.SIGNIN_WITH_OTP;
  }

  get modeUnknown(): boolean {
    return this.signInMode === constant.SIGNIN_UNDEFINED;
  }
}

import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {BehaviorSubject, SubscriptionLike} from 'rxjs';
import {take} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

import {HttpService} from './http.service';
import {apiUrls} from '../config';
import {SigninComponent} from '../../auth/signin/signin.component';
import {SignupComponent} from '../../auth/signup/signup.component';
import {ToastService} from './toast.service';
import {ToolsService} from './tools.service';
import {environment} from '../../../environments/environment';
import {AbstractService} from './abstract.service';
import {SocialAuthService, SocialUser} from './social/social-auth.service';
import {LazyService} from './lazy.service';

interface User {
  id: number;
  email: string;
  email_verified_at: string;
  created_at: string;
  updated_at: string;
  firstName: string;
  lastName: string;
  companyName: string;
  title: string;
  phone: string;
  avatar?: any,
  lastLogin: string;
  socialId?: string;
  photoUrl?: string;
  provider?: string;
  countryId?: number
  fullName: string;
  roles?: any,
  dates?: any,
  type: any;
  config?: any
}

export type UserIdentity = {
  id: number;
  name: string;
  email: string;
  token: string;
  tokenExpiresAt: string;
  csrfToken: string;
  role: string;
  currentRole: string;
  data?: any;
}

export type Roles = {
  guest: boolean;
  customer: boolean;
  agent: boolean;
  owner: boolean;
  admin: boolean;
  manager: boolean;
}

export type RoleKey = keyof Roles;

export type UserConfig = {
  [key: string]: string|number|boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractService {

  override endpoint = apiUrls.auth.user;
  override name = 'user';

  private _identity: UserIdentity|null = null;
  private _modalType: any = null;
  private _userSubscription!: SubscriptionLike;
  private _userInfo: any|null = null;
  public isPassthrough = false;
  public loggedIn = false;
  private isLoggedIn$ = new BehaviorSubject<boolean>(false);
  public identityChange$: BehaviorSubject<UserIdentity|null> = new BehaviorSubject<UserIdentity|null>(null);
  public userInfoChange$: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
  public roles: Roles = {
    guest: false,
    customer: false,
    agent: false,
    owner: false,
    admin: false,
    manager: false,
  };
  public config: UserConfig[] = [
    {'notifications.booking.email': true},
    {'notifications.booking.sms': true},
    {'notifications.booking.whatsapp': true},
  ];
  private _echoLoaded = false;

  constructor(override http: HttpService,
              private modal: NgbModal,
              private social: SocialAuthService,
              private router: Router,
              private toast: ToastService,
              private tools: ToolsService,
              private lazy: LazyService) {
    super(http);

    this.identityChange$.subscribe({
      next: async (user: UserIdentity|null) => {
        if (user && user?.id && !this._echoLoaded) {
          await this.lazy.get(() =>
            import('../services/echo.service').then((s) => {
              this._echoLoaded = true;
              return s.EchoService
            })
          );
        }
        this.getUserInfo();
      }
    });

    this.modal.activeInstances.subscribe((modals: NgbModalRef[]) => {
      modals.forEach((modalRef: NgbModalRef) => {
        if (modalRef.componentInstance instanceof SigninComponent || modalRef.componentInstance instanceof SignupComponent) {
          modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
            // Get modalType object from modal component to determine user type (owner, agent, customer)
            this._modalType = receivedEntry;
          });
        }
      })
    })

    this.social.authState.subscribe((user: SocialUser|null) => {
      if (user != null) {
        user = Object.assign(user, this._modalType);
        this.socialLogin(user).subscribe({
          next: (user: any) => {
            if (environment.debug) {
              console.log('socialLogin', user);
            }
            if (this.modal.hasOpenModals()) {
              this.modal.dismissAll('Success');
            }
            this.identity = user as UserIdentity;
            // redirect to dashboard
            if (user !== null) {
              const page = this.is('admin') ? '/users' : (this.is('manager') ? '/pages' : '/bookings');
              this.router.navigate(['/account' + page]);
            }
          },
          error: (error: any) => {
            const parsed = this.tools.parseError(error);
            this.toast.show(parsed.message, {title: parsed.title, status: 'danger'});
          }
        });
      }
      // this.user = user;
      // this.loggedIn = (user != null);
    });
  }

  private storeIdentity(value: UserIdentity|null) {
    if (value) {
      localStorage.setItem('user', JSON.stringify(value));
    } else {
      localStorage.removeItem('user');
    }
  }

  get identity(): UserIdentity|null {
    if (!this._identity) {
      const user: any = localStorage.getItem('user');
      if (user) {
        this._identity = JSON.parse(user);
        this.identityChange$.next(this._identity);
      }
    }
    return this._identity;
  }

  set identity(value: UserIdentity|null) {
    this._identity = value;
    let redirect = null;
    if (value) {
      if (value?.data?.redirect) {
        redirect = value.data.redirect;
        // Data is used once (e.g. for redirect) and must be removed
        delete value.data.redirect;
      }
    }
    this.storeIdentity(value);
    this.identityChange$.next(value);
    if (redirect) {
      this.router.navigate([redirect]);
      this.isPassthrough = false;
    }
  }

  get hasIdentity(): boolean {
    return this.identity !== null;
  }

  get userInfo(): any {
    return this._userInfo;
  }

  set userInfo(value: any) {
    this._userInfo = value;
    for (const [role] of Object.entries(this.roles)) {
      this.roles[role as RoleKey] = this.is(role);
    }
    this.roles.guest = value === null;
    if (this.isUser(value)) {
      if (value?.config && Array.isArray(value?.config) && value.config.length) {
        value.config.forEach(option => {
          const field = option.name.replace('user.config.', '');
          const item = this.config.find(config => {
            return config[field] !== undefined;
          });
          if (item) {
            item[field] = option?.value;
          }
        });
      } else {
        value.config = this.config;
      }
    }
    this.userInfoChange$.next(this._userInfo);
    this.toggleLogin(this._userInfo !== null);
  }

  get currentRole(): string {
    if (this._identity && this._identity?.currentRole) {
      return this._identity.currentRole;
    } else if (this.userInfo?.highestRole) {
      return this.userInfo?.highestRole?.name;
    }
    return 'guest';
  }

  set currentRole(value: string) {
    if (!this._identity) {
      console.warn('Empty identity');
    } else {
      this._identity.currentRole = value;
      this.storeIdentity(this._identity);
      this.userInfoChange$.next(this._userInfo);
    }
  }

  public is(roles: string|string[]): boolean {
    if (this.userInfo && this.userInfo?.role && Array.isArray(this.userInfo.role)) {
      const role = this.userInfo.role.find((item: any) => {
        if (Array.isArray(roles)) {
          return roles.find((roleName: string) => {
            return item.name === roleName;
          });
        } else {
          return item.name === roles;
        }
      });
      return role !== undefined;
    } else if (!this.userInfo && typeof roles === 'string' && roles === 'guest') {
      return true;
    }
    return false;
  }

  public isUser(obj: any): obj is User {
    return obj && 'id' in obj && 'email' in obj && 'phone' in obj && 'firstName' in obj;
  }

  get isOwner(): boolean {
    return this.loggedIn && this.is('owner');
  }

  get isAdmin(): boolean {
    return this.loggedIn && this.is('admin');
  }

  public role(): string {
    const result: string[] = [];
    this.userInfo.role.forEach((role: any) => {
      result.push(role.name);
    });
    return result.join(',');
  }

  /**
   * Returns user's option value by name
   * Do not confuse with global.option.get() that returns global option value
   * @param name
   * @param def
   */
  public option(name: string, def: any = null): any {
    if (Array.isArray(this.config)) {
      const option = this.config.find(item => {
        return this.tools.isObject(item) && (name === Object.keys(item)[0]);
      });
      if (option && this.tools.isObject(option)) {
        return Object.values(option)[0];
      }
    }
    return def;
  }

  /**
   * NOTE: Never call this method inside _fetchData (in AbstractComponent and its descendant components)
   */
  public getUserInfo() {
    if (this._identity) {
      // NOTE: do not double request in case same user
      if (this.userInfo !== null && this._identity?.id === this.userInfo?.id) {
        return;
      }
      if (!this._userSubscription || this._userSubscription.closed) {
        this._userSubscription = this.user().pipe(take(1)).subscribe({
          next: (user: any) => {
            if (environment.debug || !environment.production) {
              console.log('getUserInfo', user);
            }
            if (!this.userInfo || (user?.id !== this.userInfo?.id)) {
              this.userInfo = user;
            }
          },
          error: error => {
            if (environment.debug || !environment.production) {
              console.warn('getUserInfo', error);
            }
            this.userInfo = null;
          }
        });
      }
    } else {
      this.userInfo = null;
      if (this._userSubscription && !this._userSubscription.closed) {
        this._userSubscription.unsubscribe();
      }
    }
  }

  public getImage(user: any = null, image: any = null) {
    user = user ?? this.userInfo;
    if (!image && Array.isArray(user?.avatar) && user.avatar.length > 0) {
      image = user.avatar[0];
      if (!image?.mid && !image?.small) {
        return null;
      }
    }
    return image;
  }

  override imageUrl(model: any = null, image: any = null, thumb: string | null = null) {
    // const defImage = `/assets/img/avatars/14.png`;
    const defImage = `/assets/img/logo/logo.svg`;
    model = model ?? this.userInfo;
    image = this.getImage(model, image);
    if (image) {
      const fileName = encodeURIComponent(thumb ? image[thumb] : image.fileName);
      if (!fileName) {
        return defImage;
      }
      return `https://${environment.serverUrl}/storage/${this.name}/image/${model.id}/${fileName}`;
    } else if (model?.photoUrl) {
      return model?.photoUrl;
    }
    return defImage;
  }

  async loadSignIn() {
    await import('../../auth/auth.module').then(m => m.AuthModule);
    return await import("../../auth/signin/signin.component").then(c => c.SigninComponent);
  }

  async loadSignInPrompt() {
    await import('../../auth/auth.module').then(m => m.AuthModule);
    return await import("../../auth/prompt/login-prompt.component").then(c => c.LoginPromptComponent);
  }

  async loadSignUp() {
    await import('../../auth/auth.module').then(m => m.AuthModule);
    return await import("../../auth/signup/signup.component").then(c => c.SignupComponent);
  }

  async loadSignUpPrompt() {
    await import('../../auth/auth.module').then(m => m.AuthModule);
    return await import("../../auth/prompt/signup-prompt.component").then(c => c.SignupPromptComponent);
  }

  signin() {
    this.loadSignIn().then(SigninComponent => {
      this.modal.open(SigninComponent, {size: 'lg', centered: true});
    });
  }

  signup(item: any) {
    this.loadSignUp().then(SignupComponent => {
      const modalRef = this.modal.open(SignupComponent, {size: 'lg', centered: true});
      const [key, value] = Object.entries(item)[0];
      // key=role, value=customer
      modalRef.componentInstance[key] = value;
    });
  }

  signout() {
    this.router.navigate(['/auth/signout']);
  }

  // Toogle Loggedin
  toggleLogin(state: boolean): void {
    this.loggedIn = state;
    this.isLoggedIn$.next(state);
  }

  // Status
  status() {
    if (this.identity === null) {
      this.toggleLogin(false);
    } else {
      const tokenExpiresAt = new Date(this.identity.tokenExpiresAt);
      const currentDate = new Date();
      if (tokenExpiresAt > currentDate) {
        this.toggleLogin(true);
      } else {
        this.toggleLogin(false);
        console.warn('Token Expired!');
      }
    }
    return this.isLoggedIn$.asObservable();
  }

  // Login
  login(data: { emailOrPhone: string, password: string, isPhone?: boolean }) {
    return this.http.post(apiUrls.auth.login, data, () => null);
  }

  // Social network login
  socialLogin(user: any) {
    return this.http.client.post(this.http.url(apiUrls.auth.social), user);
  }

  socialLogout() {
    // this.social.signOut(true).then().finally();
  }

  // OTP Login
  otpLogin(data: { email?: string, phone?: string, otpCode: string }) {
    return this.http.post(apiUrls.auth.otpLogin, data, () => null);
  }

  sendLoginLink(data: any) {
    return this.http.post(apiUrls.auth.user.loginLink, data, () => null);
  }

  // Register
  register(data: Params) {
    return this.http.client.post(this.http.url(apiUrls.auth.register), data);
  }

  // Login passthrough
  passthrough(data: any) {
    return this.http.client.post(this.http.url(apiUrls.auth.passthrough), data);
  }

  // Create password
  password(data: any) {
    return this.http.client.post(this.http.url(apiUrls.auth.password), data);
  }

  // User info
  user(data: any = null) {
    if (data) {
      if (environment.debug || !environment.production) {
        console.log('Edit USER', this.identity);
      }
      return this.http.client.post(this.http.url(apiUrls.auth.user.edit), data);
    }
    if (environment.debug || !environment.production) {
      console.info('ME', this.identity);
    }
    return this.http.client.post(this.http.url(apiUrls.auth.user.me), {});
  }

  // Users list
  users() {
    if (environment.debug || !environment.production) {
      console.log('Get USERS', this.identity);
    }
    return this.http.client.post(this.http.url(apiUrls.auth.user.list), {});
  }

  // Logout
  logout(allDevices: boolean) {
    return this.http.client.post(
      this.http.url(apiUrls.auth.logout), {
        allDevices: allDevices
      }
    );
  }

  roles$() {
    return this.http.list(apiUrls.roles.list);
  }

  /**
   * Login as other user
   * @param params
   */
  impersonate$(params: Params = {}) {
    return this.http.post(apiUrls.auth.user.impersonate, params, () => null);
  }

  bank$(params: Params = {}) {
    if (environment.debug || !environment.production) {
      console.log('BANK details', params);
    }
    return this.http.client.post(this.http.url(apiUrls.auth.user.bank), params);
  }

  /**
   * sends contact-us message
   * @param params
   */
  contact$(params: Params) {
    return this.http.post(this.endpoint.contact, params, () => null);
  }

  // Forgot Pass
  forgot(email: string) {
    return this.http.client.post('http://localhost:8000/api/forgot', {email: email});
  }

  // Reset Pass
  reset(token: string, password: string, password_confirmation: string) {

    const data = {
      token: token,
      password: password,
      password_confirmation: password_confirmation,
    }
    return this.http.client.post('http://localhost:8000/api/reset', data);
  }

  // --------------------------- Social login ---------------------------
  // Facebook
  loginWithFacebook(callback: (user: SocialUser|null) => void) {
    // this.social.signOut(true).then(() => {
    //   console.log('fb signed out');
    // }).finally(() => {
    //   this.social.signIn(FacebookLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
    //     if (typeof callback === 'function') {
    //       callback(user);
    //     }
    //   });
    // });
  }

  // Google
  googleRefreshToken(): void {
    // this.social.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }
}

import {Params} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {apiUrls} from '../config';
import {HttpService} from './http.service';
import {Injectable} from '@angular/core';

export type BufferMessage = {
  guid: string;
  message: string;
  callback?: (response: any)=>void;
}

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class BufferService {
  public messageReceived$: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
  public sendMessage$: BehaviorSubject<BufferMessage|null> = new BehaviorSubject<BufferMessage|null>(null);

  constructor(private http: HttpService) {}

  chat$(params: Params = {}) {
    return this.http.post(apiUrls.echo.chat, params, () => null);
  }
}

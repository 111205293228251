import {Component, Input} from '@angular/core';

import {AbstractComponent} from '../../../../core/components/abstract.component';
import {NgClass, NgIf} from '@angular/common';

/**
 * Content Card Component
 */
@Component({
  selector: 'app-content-card',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class CardContentComponent extends AbstractComponent {

  private _content: any;

  @Input()
  get content(): any {
    return this._content;
  }
  set content(value: any) {
    this._content = value;
    this.global.content.initLayout(value);
  }

  /**
   * @deprecated Swiper is not used in card-content
   */
  @Input() withSwiper = false;
}

<!-- Navbar-->
<header class="navbar navbar-expand-lg navbar-light bg-light fixed-top" data-scroll-header
        (window:scroll)="windowScroll()">
  <div class="container" [ngClass]="{'wide': global.wideHeader}">
<!-------------------- Main logo -------------------->
    <a class="navbar-brand me-3 me-xl-4" routerLink="">
      <img class="d-block"
           src="{{assets}}/img/logo/logo-yacht-rental-charter-boat{{isMobile ? '-mob' : ''}}.webp"
           alt="Book.Boats boat charter yacht rental"
           [attr.width]="isMobile ? 155 : 200"
           [attr.height]="isMobile ? 24 : 31">
    </a>
<!-------------------- Top buttons -------------------->
    <button class="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            (click)="isMobile ? global.SidebarShow() : global.toggleMobileMenu()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <button class="btn btn-primary btn-sm ms-2 order-lg-3 me-2"
            *ngIf="global.auth.isOwner && !global.isBackend"
            (click)="global.addBoat()"
            data-bs-toggle="modal"
            aria-label="Add boat">
      <i class="fi-plus"></i><span class='d-none d-sm-inline ms-2'>Add boat</span>
    </button>
    <button class="btn btn-sm text-primary d-lg-block order-lg-3 p-0 ps-2"
            *ngIf="!global.auth.loggedIn"
            (click)="global.auth.signin()"
            data-bs-toggle="modal"
            aria-label="Sign in">
      <i class="fi-login"></i><span class='d-none d-sm-inline ms-2'>Sign in</span>
    </button>
    <button class="btn btn-sm text-primary d-lg-block order-lg-3 p-0 ps-2"
            *ngIf="global.auth.loggedIn && (isMobile || !global.isBackend)"
            (click)="global.auth.signout()"
            data-bs-toggle="modal"
            aria-label="Sign out">
      <i class="fi-logout"></i><span class='d-none d-lg-inline ms-2'>Sign out</span>
    </button>
<!-------------------- Top menu -------------------->
    <ng-container *ngIf="isMobile && !global.isBackend">
      <app-side-menu [type]="global.auth.loggedIn ? 'backend' : 'frontend'"></app-side-menu>
    </ng-container>
    <div class="collapse navbar-collapse order-lg-2 justify-content-end" id="navbarNav">
      <app-top-menu></app-top-menu>
      <!-- Account menu -->
      <app-nav-menu ngProjectAs="ul" *ngIf="global.auth.loggedIn"></app-nav-menu>
    </div>
  </div>
</header>
<!-- Page content-->

<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

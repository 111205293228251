<ul class="navbar-nav navbar-nav-scroll align-items-lg-center mt-0" style="max-height: 35rem;" *ngIf="global.auth.userInfo">
  <li class="nav-item dropdown d-none d-lg-inline-block">
    <a class="nav-link pe-0 userpic small rounded-circle"
       role="button"
       [routerLink]="['/account', 'info']"
       [ngStyle]="{'background-image': 'url(\'' + global.auth.imageUrl() + '\')'}">
    </a>
  </li>
  <li class="nav-item" [ngClass]="{'dropdown' : !global.isBackend}">
    <div class="nav-link ps-2 pe-0"
         data-bs-toggle="dropdown"
         role="button"
         aria-expanded="false"
         (click)="global.isBackend ? userInfo() : global.onMenuClick($event)"
         [ngClass]="{'dropdown-toggle d-flex align-items-center pe-lg-2' : !global.isBackend}">
      <span>{{global.auth.userInfo.firstName ?? global.auth.userInfo.fullName|midTruncate:[20]}}</span>
    </div>
    <ul class="dropdown-menu" [ngClass]="{'d-none' : global.isBackend}">
      <ng-container *ngFor="let item of menu; let last = last">
        <ng-container *ngIf="able(item.roles)">
          <li>
            <a class="dropdown-item"
               [routerLink]="item.link"><i class="fs-base opacity-50 me-2" [ngClass]="item.icon"></i>{{item.label}}</a>
          </li>
          <li class="dropdown-divider" *ngIf="!last"></li>
        </ng-container>
      </ng-container>
    </ul>
  </li>
</ul>

import {AfterViewInit, Directive, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UntypedFormBuilder} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

import {GlobalService} from '../../core/services/global.service';
import {UserIdentity} from '../../core/services/auth.service';
import {AutoUnsub} from '../../core/decorators/auto-unsub';

@Directive()
@AutoUnsub()
export abstract class AbstractAuthComponent implements OnInit, AfterViewInit {

  submit = false;

  constructor(protected router: Router,
              protected formBuilder: UntypedFormBuilder,
              protected global: GlobalService,
              public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.submit = false;
  }

  ngAfterViewInit(): void {
    // Override in child class
  }

  auth(authFn: Observable<any>) {
    authFn.pipe(take(1)).subscribe({
      next: (user: any) => {
        this.submit = false;
        if (user) {
          this.activeModal.close('Success');
          this.global.auth.identity = (user as UserIdentity);
        }
      },
      error: (error: any) => {
        this.submit = false;
        const parsed = this.global.tools.parseError(error);
        this.global.toast.show(parsed.message, {
          title: parsed.title,
          status: 'danger'
        });
      }
    });
  }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgTemplateOutlet} from '@angular/common';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {constant} from '../../core/config';
import {GlobalService} from '../../core/services/global.service';
import {LoginPromptComponent} from '../prompt/login-prompt.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  standalone: true,
  imports: [NgTemplateOutlet, LoginPromptComponent],
})
export class SigninComponent implements OnInit {

  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  assets = constant.IMAGE_PATH;
  private _modalType = {
    mode: 'signin',
    userRole: 'customer'
  }

  constructor(public activeModal: NgbActiveModal, public global: GlobalService) {}

  signup() {
    this.activeModal.close('Ok');
    this.global.auth.signup({role: 'customer'});
  }

  get modalType(): any {
    return this._modalType;
  }

  ngOnInit(): void {
    this.passEntry.emit(this.modalType);
  }

}

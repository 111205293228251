import {Injectable} from '@angular/core';

import {AbstractService} from './abstract.service';
import {apiUrls} from '../config';

@Injectable()
export class PaymentSystemService extends AbstractService {

  override useCache = true;
  override endpoint = apiUrls.paymentSystem;
  override name = 'paymentSystem';

}

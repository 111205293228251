import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {ToastService} from './toast.service';
import {ToolsService} from './tools.service';

export type Response = {
  success: boolean;
  data: any | any[];
  cached?: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    withCredentials: true,
  };

  constructor(public client: HttpClient,
              private toast: ToastService,
              private tools: ToolsService) { }

  public url(url: string): string {
    return 'https://' + environment.serverUrl + url;
  }

  post(url: string, data: any, _tap: (response: any) => void): Observable<any> {
    return this.client.post<any>(this.url(url), data, this.httpOptions).pipe(
      tap(_tap),
      catchError(this.handleError<any>('post'))
    );
  }

  /** GET record from the server */
  get(url: string, data: any = null): Observable<Response> {
    const options = Object.assign({}, this.httpOptions, {params: data});
    return this.client.get<Response>(this.url(url), options);
  }

  /** GET blob data */
  blob(url: string, data: any = null): Observable<Blob> {
    return this.client.get(this.url(url), {
      withCredentials: true,
      responseType: 'blob',
      params: data
    });
  }

  /** GET records from the server */
  list(url: string, data: any = null): Observable<Response> {
    return this.get(url, data).pipe(
      tap(_ => this.log('fetched')),
      catchError(this.handleError<Response>('HTTP::get', {success: false, data:[]}))
    );
  }

  /** GET record by id. Will 404 if id not found */
  getId(url: string, id: number): Observable<any> {
    url = this.url(`${url}/${id}`);
    return this.client.get<any>(url).pipe(
      tap(_ => this.log(`fetched id=${id}`)),
      catchError(this.handleError<any>(`get id=${id}`))
    );
  }

  /* GET record whose name contains search term */
  search(url: string, term: string): Observable<any[]> {
    if (!term.trim()) {
      // if not search term, return empty boat array.
      return of([]);
    }
    return this.client.get<any[]>(`${url}/?name=${term}`).pipe(
      tap(x => x.length ?
        this.log(`found boats matching "${term}"`) :
        this.log(`no boats matching "${term}"`)),
      catchError(this.handleError<any[]>('search', []))
    );
  }

  //////// Save methods //////////

  /** POST: add a new record to the server */
  add(url: string, data: any): Observable<any> {
    return this.post(url, data, (newRecord: any) => this.log(`added id=${newRecord.id}`));
  }

  /** DELETE: delete record by ID from the server */
  delete(url: string, id: number, data: any = null): Observable<any> {
    url = this.url(`${url}/${id}`);
    const options = Object.assign({}, this.httpOptions, {params: data});
    return this.client.delete<any>(url, options).pipe(
      tap(_ => this.log(`deleted id=${id}`)),
      catchError(this.handleError<any>('delete'))
    );
  }

  /** PUT: update the boat on the server */
  update(url: string, data: any, _tap: (response: any) => void): Observable<any> {
    return this.client.put<any>(this.url(url), data, this.httpOptions).pipe(
      tap(_tap),
      catchError(this.handleError<any>('update'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (response: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      let title = `${this.tools.ucFirst(operation)} failed`;
      let messages: string[] = [];
      let message = response?.message ?? '';
      if (response instanceof HttpErrorResponse) {
        if (response?.error) {
          if (response.error?.message) {
            message = response.error.message;
          }
          if (response.error?.errors) {
            for (const [, errors] of Object.entries(response.error.errors)) {
              if (Array.isArray(errors)) {
                messages = messages.concat(errors);
              }
            }
            if (messages.length > 0) {
              message = messages.join('::');
            }
          }
        }
        switch (response?.status) {
          case 401:
          case 422:
            if (!message) {
              message = 'Invalid credentials';
            }
            break;
          case 500:
            title = `Error ${response.status}. ${title}`;
            if (environment.production) {
              message = 'Something went wrong at our end.';
            }
            break;
        }
      }
      this.log(message);
      this.toast.show(message, {
        title: title,
        status: 'danger',
      });

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HttpService message with the MessageService */
  private log(message: string) {
    if (environment.debug) {
      console.log('HTTP::log', message);
    }
    // this.messageService.add(`HTTP: ${message}`);
  }
}

<div class="modal-body px-0 py-2 py-sm-0">
  <button class="btn-close position-absolute top-0 end-0 mt-3 me-3" type="button" data-bs-dismiss="modal" (click)="activeModal.dismiss('Cross click')"></button>
  <div class="row mx-0 align-items-center">
    <div class="col-lg-5 border-end-md p-4 p-sm-5">
      <h2 class="h3 mb-lg-5">Hey there!<br>Welcome back.</h2>
      <img class="mx-auto d-none d-lg-block" src="{{assets}}/img/logo/logo.svg" width="244" alt="Illustration" loading="lazy">
      <div class="mt-4 mt-sm-5 d-none d-lg-block">
        <ng-template [ngTemplateOutlet]="signUpLink"></ng-template>
      </div>
    </div>
    <div class="col-lg-7 px-4 pt-2 pb-4 px-sm-4 pb-sm-4 pt-md-5">
      <app-login-prompt></app-login-prompt>
      <div class="mt-4 mt-sm-5 d-lg-none">
        <ng-template [ngTemplateOutlet]="signUpLink"></ng-template>
      </div>
    </div>
  </div>
</div>
<ng-template #signUpLink>
<span class="fs-sm-xs">Don't have an account?<a href="#" (click)="signup()" class="ms-1">Sign up</a></span>
</ng-template>

import {ElementRef, Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {combineLatest, concat, defer, fromEvent, mergeMap, Observable, ObservableInput, of} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {

  private pageVisible$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) document: any) {
    this.pageVisible$ = concat(
      defer(() => of(!document.hidden)),
      fromEvent(document, 'visibilitychange')
      .pipe(
        map(e => !document.hidden)
      )
    );
  }

  elementInSight(element: ElementRef):Observable<readonly unknown[]> {

    const elementVisible$ = new Observable(observer => {
      const intersectionObserver = new IntersectionObserver(entries => {
        observer.next(entries);
      });
      intersectionObserver.observe(element.nativeElement);
      return () => { intersectionObserver.disconnect(); };
    })
    .pipe (
      mergeMap(entries => entries as ObservableInput<any>),
      map(entry => entry.isIntersecting),
      distinctUntilChanged()
    );

    return combineLatest([
        this.pageVisible$,
        elementVisible$
    ])
    .pipe(
      distinctUntilChanged()
    );
  }

}

export function AutoUnsub() {
    return function(constructor: any) {
        const orig = constructor.prototype.ngOnDestroy;
        constructor.prototype.ngOnDestroy = function() {
            for (const prop in this) {
                if (Object.prototype.hasOwnProperty.call(this, prop)) {
                    const property = this[prop];
                    if (property && typeof property.unsubscribe === 'function' && !property.closed) {
                        property.unsubscribe();
                        console.log('%c' + prop, 'color:coral', ': automatically unsubscribed');
                    }
                }
            }
            if (orig) {
                // Call the original ngOnDestroy if exists
                orig.apply(this);
            }
        };
    };
}

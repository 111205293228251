<form class="needs-validation" novalidate (ngSubmit)="validSubmit()" [formGroup]="signupForm">
  <div>
    <div class="mb-3 form-floating">
      <input class="form-control"
             type="text"
             id="signup-name"
             placeholder="Name"
             formControlName="name"
             autocomplete="username"
             [ngClass]="{'is-invalid': controls['name'].touched && controls['name'].errors}">
      <label for="signup-name">Name</label>
    </div>
    <div class="mb-3 form-floating">
      <input class="form-control"
             type="email"
             id="signup-email"
             placeholder="Enter your email"
             formControlName="email"
             autocomplete="email"
             [ngClass]="{'is-invalid': controls['email'].touched && controls['email'].errors}">
      <label for="signup-email">Email</label>
    </div>
    <div class="mb-3 form-floating">
      <template #phoneTmpl></template>
    </div>
    <div class="mb-3">
      <div class="password-toggle form-floating">
        <input class="form-control"
               [type]="fieldTextType ? 'text' : 'password'"
               formControlName="password"
               id="signup-password"
               placeholder="Enter password"
               autocomplete="current-password"
               [ngClass]="{'is-invalid': submit && controls['password'].errors}">
        <label for="signup-password">Password</label>
        <label class="password-toggle-btn d-flex justify-content-end" aria-label="Show/hide password">
          <input class="password-toggle-check" type="checkbox">
          <span class="password-toggle-indicator" (click)="toggleFieldTextType()"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="form-check mb-3">
    <input class="form-check-input"
           type="checkbox"
           id="agree-to-terms"
           formControlName="agreement"
           [ngClass]="{'is-invalid': controls['agreement'].touched && controls['agreement'].errors}">
    <label class="form-check-label fs-sm" for="agree-to-terms">I agree to the <a href="/terms-of-use" target="_blank">Terms of use</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a></label>
  </div>
  <div class="mb-3 recaptcha">
    <template #captchaTmpl></template>
  </div>
  <button class="btn btn-primary btn-lg w-100 mb-3"
          type="submit">
    <ng-container *ngIf="!submit; else btnLoading">Sign up</ng-container>
  </button>
  <ng-template #btnLoading>
    <span class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"></span>
  </ng-template>
</form>
<div class="d-flex align-items-center py-3 mb-3">
  <hr class="w-100">
  <div class="px-3">Or</div>
  <hr class="w-100">
</div>
<div class="d-flex align-items-center justify-content-center flex-wrap">
  <div class="d-flex align-items-center justify-content-center w-100 mb-3">
    <google-signin-button [selectable]="true"></google-signin-button>
  </div>
<!--  <button class="btn fb-login-btn mb-3 mt-3" (click)="withFacebook()"><i class="fi-facebook-square fs-lg me-2"></i>Continue with Facebook</button>-->
</div>

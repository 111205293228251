import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {HttpService} from './http.service';
import {AppData, Currency, UserLocation} from '../appdata';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  private _location: UserLocation|null = null;
  private _currency: Currency|null = null;
  public locationChange$: BehaviorSubject<UserLocation|null> = new BehaviorSubject<UserLocation|null>(null);
  public currencyChange$: BehaviorSubject<Currency|null> = new BehaviorSubject<Currency|null>(null);
  public currencies: Currency[] = [
    {code: 'EUR', countryCode: 'EU', flag: 'fi-eu'},
    {code: 'USD', countryCode: 'US', flag: 'fi-us'},
    {code: 'JPY', countryCode: 'JP', flag: 'fi-jp'},
    {code: 'GBP', countryCode: 'GB', flag: 'fi-gb'},
    {code: 'CHF', countryCode: 'CH', flag: 'fi-ch'},
    {code: 'CAD', countryCode: 'CA', flag: 'fi-ca'},
    {code: 'AED', countryCode: 'AE', flag: 'fi-ae'},
  ];
  public exchangeRates: any = {};

  constructor(http: HttpService, private appData: AppData) { }

  get location(): UserLocation|null {
    return this._location;
  }

  set location(value: UserLocation|null) {
    this._location = value;
    this.exchangeRates = this.appData?.exchangeRates ?? {};
    if (environment.debug || !environment.production) {
      console.log('Location', value, this.exchangeRates);
    }
    this.locationChange$.next(value);
  }

  get currency(): Currency|null {
    return this._currency;
  }

  set currency(value: Currency|null) {
    this._currency = value;
    if (this._currency && value?.code && value?.countryCode) {
      this._currency.flag = `fi-${value.countryCode.toLowerCase()}`;
      if (this.exchangeRates[value.code]) {
        this._currency.rate = this.exchangeRates[value.code];
      }
      const currencyFound = this.currencies.find((currency: Currency) => {
        return currency.code === value?.code;
      });
      if (!currencyFound) {
        this.currencies.push(this._currency);
      }
      this.currencies.forEach((currency: Currency) => {
        currency.disabled = currency.code === value?.code;
      });
    }
    if (environment.debug || !environment.production) {
      console.log('Currency', this._currency, this.currencies);
    }
    this.currencyChange$.next(this._currency);
  }

}

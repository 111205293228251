import {Injectable} from '@angular/core';
import {BehaviorSubject, isObservable} from 'rxjs';

import {ToolsService} from '../tools.service';
import {GoogleAuthService} from './google-auth.service';
import {environment} from '../../../../environments/environment';

export class SocialUser {
  provider: string | undefined;
  id: string | undefined;
  email: string | undefined;
  name: string | undefined;
  photoUrl: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  authToken: string | undefined;
  idToken: string | undefined;
  authorizationCode: string | undefined;
  response: any;
}

/**
 * Providing a singleton service
 */
@Injectable({
  providedIn: 'root',
})
export class SocialAuthService {

  private _initialized = false;
  public googleAuth!: GoogleAuthService;
  public readonly google: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
  public readonly authState: BehaviorSubject<SocialUser|null> = new BehaviorSubject<SocialUser|null>(null);

  constructor(private tools: ToolsService) {}

  public initialize() {
    if (this._initialized) {
      return;
    }
    this.googleAuth = new GoogleAuthService(environment.googleClientId, {
      oneTapEnabled: false,
    }, this.tools);
    this.googleAuth.initialize(false).then((google: any) => {
      this.google.next(google);
      if (isObservable(this.googleAuth.changeUser)) {
        this.googleAuth.changeUser.subscribe((user) => {
          this.authState.next(user);
        });
      }
    });
    this._initialized = true;
  }
}

import {Component, Input, QueryList, ViewChildren, ViewContainerRef} from '@angular/core';
import {NgClass, NgIf} from '@angular/common';
import {UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';

import {constant} from '../../core/config';
import {AbstractAuthComponent} from './abstract-auth.component';
import {GoogleSigninButtonDirective} from '../../core/directives/google-signin-button.directive';

@Component({
  selector: 'app-signup-prompt',
  templateUrl: './signup-prompt.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    GoogleSigninButtonDirective,
  ],
})
export class SignupPromptComponent extends AbstractAuthComponent {

  @ViewChildren('phoneTmpl', { read: ViewContainerRef }) phoneRef!: QueryList<ViewContainerRef>;
  @ViewChildren('captchaTmpl', { read: ViewContainerRef }) captchaRef!: QueryList<ViewContainerRef>;

  private _email = '';
  private _phone = '';

  @Input() signInMode: number = constant.SIGNIN_UNDEFINED;
  @Input() canSwitch = true;
  @Input() isNewUser = false;
  @Input() userToken: string | null = null;
  @Input() role: string | null = 'customer';
  @Input()
  get email(): string {
    return this._email;
  }
  set email(val: string) {
    this._email = val;
    if (this.signupForm) {
      this.controls['email']?.patchValue(this._email);
    }
  }

  @Input()
  get phone(): string {
    return this._phone;
  }
  set phone(val: string) {
    this._phone = val;
    if (this.signupForm) {
      this.controls['email']?.patchValue(this._phone);
    }
  }

  // isPhone: boolean = false;
  fieldTextType!: boolean;
  signupForm!: UntypedFormGroup;
  countries: any[] = []; // countries;
  assets = constant.IMAGE_PATH;
  constant = constant;
  captcha: any = null;

  submitForm = (): void => {
    this.submit = true;
    const registerFn = this.global.auth.register(this.signupForm.value);
    this.auth(registerFn);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Lazy load stylesheet
    this.global.lazy.loadStylesheet('/intl-tel-input.css', 'tel-styling');

    if (!this.role) {
      this.global.toast.show(`Unknown user type`);
    }

    /**
     * Bootstrap validation form data
     */
    this.signupForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: [this.email, [Validators.required]],
      phone: '',
      password: ['', [Validators.required]],
      agreement: [true, Validators.requiredTrue],
      role: [this.role, Validators.required],
      recaptcha: null,
    });
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();

    this.global.lazy.createPhoneInput(this.phoneRef, [
      {inputId: 'signup-phone'},
      {ngClass: {'is-invalid': this.submit && this.controls['phone'].errors}},
      {separateDialCode: true},
    ], this);

    this.global.lazy.createCaptcha(this.captchaRef, this.signupForm, this.submitForm, this, (captcha) => {
      this.captcha = captcha;
    });
  }

  /**
   * Password Hide/Show
   */
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  reset() {
    this.signupForm.reset();
    this.signupForm.updateValueAndValidity();
  }

  /**
   * Bootstrap validation form submit method
   */
  validSubmit() {
    if (this.captcha) {
      this.captcha?.execute();
    }
  }


  withFacebook() {
    // this.global.auth.loginWithFacebook((user: SocialUser|null) => {
    //   if (environment.debug) {
    //     console.log('fb auth', user);
    //   }
    // });
  }

  /**
   * Returns form
   */
  get controls() {
    return this.signupForm.controls;
  }
}

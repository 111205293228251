import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {DomSanitizer, SafeHtml, SafeResourceUrl} from '@angular/platform-browser';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Options} from '@angular-slider/ngx-slider';

import {HttpService, Response} from './http.service';
import {apiUrls} from '../config';
import {environment} from '../../../environments/environment';
// import {RegisterComponent} from '../../pages/frontend/boat/modal/register/register.component';
import {SearchTerms} from '../components/abstract.component';
import {AbstractService, IListTab} from './abstract.service';
import {OptionService} from './option.service';
import {ToastService} from './toast.service';
import {ToolsService} from './tools.service';
import {GeoLocationService} from './geo-location.service';

export interface IBoatList {
  active: any[],
  inactive: any[],
}

export type BoatListKey = keyof IBoatList;

@Injectable()
export class BoatService extends AbstractService {

  override endpoint = apiUrls.boat;
  override name = 'boat';

  types: any[] = [];
  maxDays = 90;
  today = new Date();
  maxDate = new Date(this.today.setDate(this.today.getDate() + this.maxDays)).toISOString().split('T')[0]; // Plus maxDays days from today
  searchTerms: SearchTerms = {
    marina: 'all',
    date: new Date().toISOString().split('T')[0],
    from: 14,
    to: 18,
    type: '',
    // length: 20,
    minLength: 20,
    maxLength: 200,
    capacity: 10,
    // cabins: 0,
    // berths: 0,
    verified: null,
    instant: null,
    placeId: '',
  };
  maxCapacity = 60
  // maxLimit property added to avoid error in this.capacityLabel() method
  maxLimit = this.maxCapacity;
  googleMapApi = environment.googleMapApi;
  monthsCount = 2;
  /**
   * Hour Range Slider
   */
  duration = 24;
  tabs: IListTab[] = [
    {name: 'active', icon: 'fi-eye-on'},
    {name: 'inactive', icon: 'fi-eye-off'}
  ]

  public boatFetched$ = new BehaviorSubject<any>(null);
  public availabilityUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public tripDateUpdated$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(override http: HttpService,
              private option: OptionService,
              private toast: ToastService,
              private tools: ToolsService,
              private geo: GeoLocationService,
              public domSanitizer: DomSanitizer) {
    super(http);
  }

  /**
   * Returns day of week short name from selected date: mon, tue...
   */
  get dow(): string {
    const selectedDate = new Date(this.searchTerms.date);
    return selectedDate.toLocaleDateString('en-US', { weekday: 'short' }).toLowerCase();
  }

  get tripDate(): any {
    return this.searchTerms.date;
  }

  set tripDate(value: any) {
    const date = new Date(value);
    this.searchTerms.date = this.tools.dateTimeToStr(date);
    this.tripDateUpdated$.next(this.searchTerms.date);
  }

  timeRangeOptions(boat: any = null, dow: string|null = null): Options {
    dow = dow ?? this.dow;
    const [start, end] = [dow + 'Start', dow + 'End'];
    const floor = (boat && boat?.operating?.[start]) ? parseInt(boat.operating[start]) : 0;
    let ceil = (boat && boat?.operating?.[end]) ? parseInt(boat.operating[end]) : 23;
    if (ceil <= floor) {
      ceil += 24;
    }
    const minRange = (boat && boat?.minCharterPeriod) ? parseInt(boat?.minCharterPeriod) : 0;
    return {
      floor: floor,
      ceil: ceil,
      minRange: minRange,
      translate: (value: number): string => {
        const val = value > 24 ? value - 24 : (value === 24 ? 0 : value);
        return (val < 10 ? `0${val}` : val) + ':00';
      },
    }
  }

  /**
   * Length Range Slider
   */
  lengthOptions(boat: any = null): Options {
    return {
      floor: 20,
      ceil: (boat && boat?.size) ? parseInt(boat.size) : 200,
      translate: (value: number): string => {
        return value + ' ft';
      },
    }
  }

  /**
   * Guests Slider
   */
  capacityOptions(boat: any = null, maxCapacity: number | null = null): Options {
    return {
      floor: 2,
      ceil: this.capacity(boat, maxCapacity),
      maxLimit: this.maxCapacity,
      translate: this.capacityLabel,
    }
  }

  capacity(boat: any = null, maxCapacity: number | null = null): number {
    maxCapacity = maxCapacity ?? this.maxCapacity;
    const boatCapacity = (boat && boat?.capacity) ? parseInt(boat.capacity) : maxCapacity;
    return boatCapacity < 2 ? 2 : (boatCapacity > maxCapacity ? maxCapacity : boatCapacity);
  }

  capacityLabel(value: number): string {
    // Note: here 'this' is not a BoatService instance, but ngx-slider Options
    const plus = value === this?.maxLimit ? '+' : '';
    return `${value}${plus} person${value > 1 ? 's' : ''}`;
  }

  /**
   * * * * * * * REGISTER BOAT * * * * * *
   */
  register$(options: any, callback: (response: any) => void) {
    this.http.add(apiUrls.boat.add, options).subscribe({
      next: (response) => {
        callback(response);
      },
      error: (error: any) => {
        callback(error);
      }
    });
  }

  listByOwner$(params: Params = {}): Observable<Response> {
    return this.http.list(apiUrls.boat.listByOwner, params);
  }

  flipImage$(params: Params = {}): Observable<Response> {
    return this.http.post(apiUrls.boat.image.flip, params, () => null);
  }

  reorderImages$(params: Params = {}): Observable<Response> {
    return this.http.post(apiUrls.boat.image.reorder, params, () => null);
  }

  updateImage$(params: Params = {}): Observable<Response> {
    return this.http.post(apiUrls.boat.image.update, params, () => null);
  }

  recent$(params: Params = {}): Observable<Response> {
    return this.http.list(apiUrls.boat.recent, params);
  }

  faqList$(params: Params = {}): Observable<Response> {
    return this.http.list(apiUrls.boat.faq.list, params);
  }

  types$(params: Params = {}): Observable<Response> {
    if (params?.['nocache']) {
      this.types = [];
    }
    if (this.types && Array.isArray(this.types) && this.types.length) {
      return of({success: true, data: this.types, cached: true});
    }
    return this.http.list(apiUrls.boat.types, params);
  }

  features$(): Observable<Response> {
    return this.http.list(apiUrls.boat.features);
  }

  periods$(): Observable<Response> {
    return this.http.list(apiUrls.boat.periods);
  }

  transfer$(params: Params = {}): Observable<Response> {
    return this.http.post(apiUrls.boat.transfer, params, () => null);
  }

  transfer(params: Params = {}) {
    this.transfer$(params).subscribe(response => {
      if (response?.data) {
        const messages = [];
        if (response.data?.succeeded > 0) {
          messages.push('Transferred ' + this.tools.plural('boat', response.data.succeeded));
          this.listingUpdated$.next(true);
        }
        if (response.data?.failed > 0) {
          messages.push('Failed ' + this.tools.plural('boat', response.data.failed) + '::');
          if (Array.isArray(response.data?.failedList)) {
            response.data.failedList.forEach((boat: string) => {
              messages.push(boat);
            });
          }
        }

        this.toast.show(messages.join('::'), {
          title: response?.success === true ? 'Done' : 'Sorry',
          icon: 'switch-horizontal',
          status: response?.success === true ? 'success' : 'danger',
        });
      }
    });
  }

  centerPoint(marina: any) {
    if (marina?.options) {
      return JSON.parse(marina.options);
    }
    return {
      location: {
        lat: 25.2201,
        lon: 55.2563,
      },
    }
  }

  mapUrl(marina: any, centerPoint: any = null): SafeResourceUrl {
    centerPoint = centerPoint ?? this.centerPoint(marina);
    const url = `https://www.google.com/maps/embed/v1/place?q=${centerPoint.location.lat}%2C${centerPoint.location.lon}&key=${environment.googleMapApi}&maptype=satellite`;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // --------------------- Modals ---------------------

  /**
   *
   * @param boat
   */
  // bookingModal(boat: any) {
  //   const modal = this.modal.open(BookingComponent, {size: 'lg', centered: true});
  //   (modal.componentInstance as BookingComponent).boat = boat;
  //   // (modal.componentInstance as BookingComponent).destroy();
  //   // modal.componentInstance.destroy();
  // }

  registerModal() {
    // this.modal.open(RegisterComponent, {size: 'lg', centered: true});
    // (modal.componentInstance as RegisterComponent).boat = boat;
  }

  changeHour(value: number, boundary: boolean): void {
    if (boundary) {
      this.searchTerms.from = value;
    } else {
      this.searchTerms.to = value;
    }
    this.duration = this.searchTerms.to - this.searchTerms.from;
  }

  changeCapacity(boat: any, value: number): void {
    const maxCapacity = this.capacity(boat);
    this.searchTerms.capacity = (value <= maxCapacity) ? value : maxCapacity;
  }

  period(boat: any, duration: number = this.duration): string {
    return duration + ' ' + boat.period.name + (duration > 1 ? 's' : '');
  }

  shouldConvert(boat: any) {
    return this.geo?.currency &&
      this.geo.currency.code !== boat.currency.code &&
      this.geo.currency?.rate &&
      this.geo.exchangeRates?.[boat.currency.code];
  }

  defaultPrice(boat: any) {
    if (Array.isArray(boat?.prices)) {
      return boat.prices.find((item: any) => {
        return item.periodId === boat.periodId;
      });
    }
    return null;
  }

  price(boat: any, agent = false, priceRecord: any = null, shouldConvert: boolean | null = null) {
    let price = 0;
    priceRecord = priceRecord ?? this.defaultPrice(boat);
    if (priceRecord) {
      price = agent ? priceRecord.priceAgent : priceRecord.price;
    }
    shouldConvert = shouldConvert ?? this.shouldConvert(boat);
    if (price > 0 && shouldConvert) {
      // convert to USD first, then to local currency
      price = Math.ceil((price / this.geo.exchangeRates[boat.currency.code]) * (this.geo.currency?.rate ?? 1));
    }
    return price;
  }

  formattedPrice(boat: any, agent = false, options: any = {}): SafeHtml {
    const priceRecord = this.defaultPrice(boat);
    const subClass = options?.subclass ?? 'ms-1';
    let periodLabel = boat.period.name;
    if (priceRecord?.persons > 0) {
      const pluralVal = this.tools.plural('person', priceRecord.persons);
      periodLabel += ` per ${pluralVal}`;
    }
    const shouldConvert = this.shouldConvert(boat);
    const currencyCode = shouldConvert ? this.geo?.currency?.code : boat.currency.code;
    const html = `${currencyCode} ${this.price(boat, agent, priceRecord, shouldConvert)}<sub class="${subClass}">/ ${periodLabel}</sub>`
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  prices(boat: any, agent = false, duration: number = this.duration) {
    const priceRecord = this.defaultPrice(boat);
    const persons = priceRecord?.persons > 0 ? priceRecord.persons : 1;
    const price = this.price(boat, agent, priceRecord);
    const pricePeriod = price * duration * persons;
    return this.totals(price, pricePeriod, boat.currency);
  }

  totals(price: number, totalPrice: number, currency: any) {
    const fees = {
      listingPerCent: this.option.get('global.listing.service.fee', 0),
      renterPerCent: this.option.get('global.renter.service.fee', 0)
    };
    totalPrice = this.tools.roundDec(totalPrice, 2);
    const listingFee = this.tools.roundDec(fees.listingPerCent > 0 ? totalPrice * (fees.listingPerCent / 100) : 0, 2);
    const renterFee = this.tools.roundDec(fees.renterPerCent > 0 ? totalPrice * (fees.renterPerCent / 100) : 0, 2);
    const ownerPayout = this.tools.roundDec(totalPrice - listingFee, 2);
    const renterTotal = this.tools.roundDec(totalPrice + renterFee, 2);
    return {
      price: `${currency.code} ${price}`,
      listingFee: `${currency.code} ${listingFee}`,
      renterFee: `${currency.code} ${renterFee}`,
      payout: `${currency.code} ${ownerPayout}`,
      offer: `${currency.code} ${totalPrice}`,
      total: `${currency.code} ${renterTotal}`,
    }
  }

  /**
   * * * * * * * BOOKINGS * * * * * *
   */
  book$(loggedIn: boolean, data: any) {
    const url = apiUrls.boat.book + (loggedIn ? '/a' : '/g');
    if (environment.debug || !environment.production) {
      console.log('BOOK', url, data);
    }
    return this.http.post(url, data, () => null);
  }
}

import {Injectable} from '@angular/core';

export type SeoTagLength = 'missing' | 'poor' | 'good' | 'oversize' | 'duplicate';

@Injectable({
  providedIn: 'root',
})
export class SeoService {

  private _pages: any = {};

  set bbPages(value: any) {
    if (value) {
      this._pages = value; // JSON.parse(window.atob(value));
    }
  }

  get pages(): any {
    return this._pages;
  }

  set pages(value: any) {
    this._pages = value;
  }

  public seoHealth(status: SeoTagLength) {
    const map = {
      'missing': 'dark',
      'poor': 'danger',
      'oversize': 'warning',
      'duplicate': 'accent',
      'good': 'success',
    };
    return map?.[status];
  }
}

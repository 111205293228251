<article class="d-flex align-items-start mb-3">
  <a class="d-none d-sm-block flex-shrink-0 me-sm-4 mb-sm-0 mb-3 article-link" href="/{{content.type}}/{{content.name}}">
    <img class="rounded-3 blog-preview-img" src="{{global.content.imageUrl(content, null, 'tiny')}}"
         width="100"
         alt="Blog post"
         loading="lazy"></a>
  <div class="article-body">
    <h5 class="mb-1 fs-base"><a class="nav-link" href="/{{content.type}}/{{content.name}}">{{content.title}}</a></h5>
    <p class="mb-0 fs-sm blog-preview"
       [ngClass]="{'d-none': !content.bodyShort}"
       [innerHTML]="content.bodyShort ?? ''"></p>
    <a class="nav-link nav-link-muted d-inline-block me-3 p-0 fs-xs fw-normal pe-none"
       *ngIf="(content?.pageLayout?.date?.visible ?? false)"
       href="#"
       aria-disabled="true">
      <i class="fi-calendar mt-n1 me-1 fs-sm align-middle opacity-70"></i>{{content.updated_at}}</a>
<!--    <a class="nav-link nav-link-muted d-inline-block me-3 p-0 fs-xs fw-normal" href="javascript:void(0);">-->
<!--      <i class="fi-chat-circle mt-n1 me-1 fs-sm align-middle opacity-70"></i>2 comments</a>-->
  </div>
</article>

export class FetchStatus {
  private _fetching = false; // True when fetching is in progress
  private _fetched = false; // True when fetching is finished no matter if it was successful or not

  get fetching(): boolean {
    return this._fetching;
  }

  set fetching(value: boolean) {
    this._fetching = value;
    if (value) {
      this._fetched = false;
    }
  }

  get fetched(): boolean {
    return this._fetched;
  }

  set fetched(value: boolean) {
    this._fetched = value;
    if (value) {
      this.fetching = false;
    }
  }

  public reset(): void {
    this._fetching = false;
    this._fetched = false;
  }
}

<!-- Account nav-->
<div class="card card-body border-0 shadow-sm p-2 p-lg-4 offcanvas offcanvas-start offcanvas-collapse"
     data-bs-scroll="false" data-bs-backdrop="false"
     id="main-sidebar">
  <div class="offcanvas-header d-flex d-lg-none align-items-center justify-content-end py-3 px-1">
    <button class="btn-close"
            type="button"
            data-bs-dismiss="offcanvas"
            aria-label="Close sidebar"
            (click)="global.SidebarHide()"></button>
  </div>
  <div class="offcanvas-body px-2 py-0">
    <ng-container *ngIf="global.auth.userInfo; else mainLogo">
      <div class="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-2">
        <div class="userpic medium position-relative rounded-circle"
             [ngStyle]="{'background-image': 'url(\'' + global.auth.imageUrl() + '\')'}"></div>
        <div class="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3">
          <h2 class="fs-lg mb-2">{{global.auth.userInfo.fullName}}</h2>
          <!--      <span class="star-rating" *ngIf="!roles.admin">-->
          <!--        <i class="star-rating-icon fi-star-filled active"></i>-->
          <!--        <i class="star-rating-icon fi-star-filled active"></i>-->
          <!--        <i class="star-rating-icon fi-star-filled active"></i>-->
          <!--        <i class="star-rating-icon fi-star-filled active"></i>-->
          <!--        <i class="star-rating-icon fi-star-filled active"></i>-->
          <!--      </span>-->
          <ng-container *ngIf="global.auth.userInfo?.roles ?? []; let roles">
            <div class="btn-group" ngbDropdown *ngIf="roles.length > 1; else singleRole">
              <button type="button" class="btn btn-sm btn-outline-secondary dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      ngbDropdownToggle>{{global.auth.currentRole | titlecase}}</button>
              <div class="dropdown-menu my-1" ngbDropdownMenu>
            <span class="dropdown-item cursor-pointer"
                  *ngFor="let role of roles"
                  (click)="switchRole(role)">{{role | titlecase}}</span>
              </div>
            </div>
            <ng-template #singleRole>
              <span class="badge rounded-pill bg-primary ms-1" *ngFor="let role of roles">{{role | titlecase}}</span>
            </ng-template>
          </ng-container>
        </div>
      </div>
      <ul class="list-unstyled fs-sm mt-3 mb-0">
        <li *ngIf="global.auth.userInfo.phone">
          <a class="nav-link fw-normal p-0" href="tel:{{global.auth.userInfo.phone}}"><i class="fi-phone opacity-60 me-2"></i>{{global.auth.userInfo.phone}}</a>
        </li>
        <li>
          <a class="nav-link fw-normal p-0" href="mailto:{{global.auth.userInfo.email}}"><i class="fi-mail opacity-60 me-2"></i>{{global.auth.userInfo.email}}</a>
        </li>
      </ul>
    </ng-container>
    <ng-template #mainLogo>
      <img class="d-block mb-2"
           src="{{assets}}/img/logo/logo-yacht-rental-charter-boat{{isMobile ? '-mob' : ''}}.webp"
           alt="Book.Boats boat charter yacht rental"
           [attr.width]="isMobile ? 155 : 200"
           [attr.height]="isMobile ? 24 : 31">
    </ng-template>
    <div class="card-nav" *ngIf="menu; let menuArray;">
      <ng-container *ngFor="let item of menuArray">
        <a class="card-nav-link d-flex justify-content-between align-items-center"
           *ngIf="able(item.roles)"
           [routerLink]="item.link"
           [ngClass]="item.active ? 'active' : ''">
          <span class="d-flex align-items-center">
            <i class="opacity-60 me-2" [ngClass]="item.icon"></i>
            <span>{{item.label}}</span>
          </span>
          <span class="badge bg-primary ms-1" *ngIf="item?.badge">{{item.badge}}</span>
        </a>
      </ng-container>
    </div>
  </div>
  <div *ngIf="['customer', 'agent'].includes(global.auth.currentRole)">
    <a class="nav-link fw-normal px-2 fs-xs"
       href="https://api.whatsapp.com/send?phone={{global.option.get('global.contact.whatsapp', '') | removewhitespaces}}"
       target="_blank"
       aria-label="Chat now on WhatsApp">
      <i class="fi-whatsapp opacity-60 me-2"></i>Boat owner? Whatsapp us on {{global.option.get('global.contact.whatsapp', '')}} to convert your account.</a>
  </div>
</div>

<!-- Filters sidebar toggle button (mobile)-->
<!--<button class="btn btn-primary btn-sm w-100 rounded-0 fixed-bottom d-lg-none"-->
<!--        type="button" data-bs-toggle="offcanvas" data-bs-target="#main-sidebar"-->
<!--        (click)="SidebarShow()"><i class="fi-align-justify me-2"></i>Menu</button>-->
<div class="vertical-overlay" (click)="global.SidebarHide()"></div>

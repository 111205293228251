<form class="needs-validation"
      novalidate
      *ngIf="loginForm"
      (ngSubmit)="validSubmit(activeModal)"
      [formGroup]="loginForm">
  <div>
    <div class="mb-3 form-floating" *ngIf="modeUnknown">
      <input class="form-control"
             type="email"
             id="signin-emailOrPhone"
             placeholder="Enter your email or phone"
             autocomplete="email"
             formControlName="emailOrPhone"
             [ngClass]="{'is-invalid': controls['emailOrPhone'].touched && controls['emailOrPhone'].errors}">
      <label for="signin-emailOrPhone">Email address or mobile number</label>
    </div>
    <div class="card card-hover card-body d-flex align-items-center justify-content-center mb-3 p-3"
         *ngIf="[constant.SIGNIN_WITH_PASSWORD, constant.SIGNIN_WITH_OTP].includes(signInMode)">
      <label class="form-check-label d-sm-flex align-items-center justify-content-between">
        <span><i class="fs-lg me-1" [ngClass]="isPhone ? 'fi-phone' : 'fi-mail'"></i>{{controls['emailOrPhone'].value}}</span>
        <a class="fs-sm ms-2 cursor-pointer"
           *ngIf="canSwitch"
           (click)="reset()">Change</a>
      </label>
    </div>
    <div class="mb-3" *ngIf="modePassword">
      <div class="password-toggle form-floating">
        <input class="form-control"
               [type]="fieldTextType ? 'text' : 'password'"
               formControlName="password"
               id="signin-password"
               placeholder="Enter password"
               autocomplete="current-password"
               [ngClass]="{'is-invalid': submit && controls['password'].errors}">
        <label for="signin-password">Password</label>
        <label class="password-toggle-btn d-flex justify-content-end" aria-label="Show/hide password">
          <input class="password-toggle-check" type="checkbox">
          <span class="password-toggle-indicator" (click)="toggleFieldTextType()"></span>
        </label>
      </div>
    </div>
    <div class="mb-3" *ngIf="modeOtp">
      <div class="form-floating mb-3">
        <input class="form-control"
               type="text"
               formControlName="otpCode"
               id="signin-otpCode"
               placeholder="Enter OTP Code"
               [ngClass]="{'is-invalid': submit && controls['otpCode'].errors}">
        <label for="signin-otpCode">Enter OTP Code</label>
      </div>
    </div>
  </div>
  <button class="btn btn-primary btn-lg w-100 mb-3"
          type="button"
          *ngIf="modeUnknown"
          (click)="withPassword()">
    <ng-container *ngIf="!submit; else btnLoading">Sign in with password</ng-container>
  </button>
  <button class="btn btn-primary btn-lg w-100 mb-3"
          type="submit"
          *ngIf="!modeUnknown"
          [disabled]="loginForm.invalid">
    <ng-container *ngIf="!submit; else btnLoading">{{isNewUser ? 'Create account' : 'Sign In'}}</ng-container>
  </button>
  <ng-container *ngIf="!isNewUser">
    <button class="btn btn-outline-info w-100 mb-3"
            type="button"
            *ngIf="!modeOtp"
            (click)="withOtp()"
            [disabled]="controls['emailOrPhone'].invalid">
      <ng-container *ngIf="!isSending; else btnLoading">Send OTP / Email sign in link</ng-container>
    </button>
    <div class="mb-3" *ngIf="isPhone">
      <small class="pb-2">We’ll text you to confirm your number. Standard message and data rates apply. <a href="/privacy-policy" target="_blank">Privacy Policy</a></small>
    </div>
  </ng-container>
  <ng-template #btnLoading>
    <span class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"></span>
  </ng-template>
</form>
<div class="d-flex align-items-center py-3 mb-3">
  <hr class="w-100">
  <div class="px-3">Or</div>
  <hr class="w-100">
</div>
<!--      <a class="btn btn-outline-info w-100 mb-3" (click)="withPhone = !withPhone" *ngIf="withPhone"><i class="fi-mail fs-lg me-1"></i>Next</a>-->
<!--      <a class="btn btn-outline-info w-100 mb-3" (click)="withPhone = !withPhone" *ngIf="!withPhone"><i class="fi-phone fs-lg me-1"></i>Continue with phone</a>-->
<div class="d-flex align-items-center justify-content-center flex-wrap">
  <div class="d-flex align-items-center justify-content-center w-100 mb-3">
    <google-signin-button [selectable]="true"></google-signin-button>
  </div>
<!--  <button class="btn fb-login-btn mb-3" (click)="withFacebook()"><i class="fi-facebook-square fs-lg me-2"></i>Continue with Facebook</button>-->
</div>

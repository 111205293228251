import {Component, TemplateRef} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {NgFor, NgIf, NgTemplateOutlet, NgClass} from '@angular/common';
import {NgbToast} from '@ng-bootstrap/ng-bootstrap';

import {ToastService} from '../services/toast.service';

@Component({
  selector: 'app-toasts',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [class]="toast?.classname"
      [autohide]="toast?.autohide ?? true"
      [delay]="toast.delay || 5000"
      (hidden)="closeMe(toast)">
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>
<!--        <ng-template ngbToastHeader class="toast-header bg-primary text-white">-->
          <div class="me-auto px-0 py-2 d-flex align-items-center justify-content-start">
            <div class="me-2 rounded-1 d-flex align-items-center pe-none"
                 [style.width.em]="1.35"
                 [style.height.em]="1.35">
              <i class="fs-lg text-{{ toast?.status ?? 'info' }}"
                 [style.textShadow]="'0 0 2px'"
                 [ngClass]="icon(toast)"></i>
            </div>
            <span class="fw-bold me-auto">{{ toast.title }}</span>
            <button type="button"
                    aria-label="Close"
                    class="btn-close cursor-pointer"
                    (click)="closeMe(toast)"></button>
          </div>
<!--        </ng-template>-->
        <div class="px-0 py-2 pe-none" [innerHTML]="parseText(toast.textOrTpl)"></div>
        <ng-container *ngIf="toast?.click">
          <button type="button"
                  aria-label="Open"
                  class="btn btn-xs btn-outline-info"
                  (click)="clickHandler(toast)">Open</button>
          <button type="button"
                  aria-label="Close"
                  class="btn btn-xs btn-outline-primary ms-2"
                  (click)="closeMe(toast)">Dismiss</button>
        </ng-container>
      </ng-template>
    </ngb-toast>`,
  host: {'class': 'toast-container position-fixed top-1 end-0 p-3 me-lg-5', 'style': 'z-index: 1200;'},
  standalone: true,
  imports: [
    NgFor,
    NgbToast,
    NgIf,
    NgTemplateOutlet,
    NgClass,
  ],
})
export class ToastsComponent {
  constructor(public toastService: ToastService, private domSanitizer: DomSanitizer) {
  }

  parseText(value: string): SafeHtml {
    const html = (value ?? 'Empty message').replaceAll('::', '<br>');
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  clickHandler(toast: any) {
    this.closeMe(toast);
    if (typeof toast?.click === 'function') {
      toast.click();
    } else {
      console.warn('Not a function');
    }
  }

  closeMe(toast: any) {
    this.toastService.remove(toast);
  }

  icon(toast: any): string {
    const prefix = 'fi-';
    let icon = 'bell';
    switch (toast?.status) {
      case 'danger':
        icon = 'alert-octagon';
        break;
      case 'primary':
        icon = 'alert-triange';
        break;
      case 'info':
        icon = 'info-circle';
        break;
      case 'success':
        icon = 'check';
        break;
    }
    return prefix + (toast?.icon ?? icon);
  }
}
